.footer {
    background: var(--nav-color);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.footer .media{
    font-size: 30px;
    border-top: 1px solid #919191;
    width: 100%;
    justify-content: center;
    gap: 10px;
    display: flex;
    padding-top: 30px;
}