#navbar {
    background: var(--nav-color);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 40px;
    z-index: 1;
}

.nav_item {
    display: inline-block;
    padding: 0px 20px;
    position: relative;
}

.dropdown_nav_list {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 40px;
    left: 0;
    background: var(--nav-color);
    transition: 0.2s;
}

.dropdown_nav_item:hover .dropdown_nav_list {
    display: flex;
}


.dropdown_nav_list .nav_link{
    color: rgb(175, 175, 175);
}

.dropdown_nav_list .nav_item{
    font-size: 14px;
    padding: 10px 20px;
}



#menu_Button{
    background: var(--active-primary-color);
    display: none;
    border: none;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 0px;
    font-size: 15px;
    height: 40px;
    width: 40px;
}

#menu_Button:hover{
    background: var(--primary-color);
}

.nav_item{
    padding: 10px 20px;
} 

@media (max-width: 875px){
    #menu_Button{
        display: block;
    }
    #nav_list{
        background: var(--nav-color);
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 43px;
        right: 3px;
    }
    .dropdown_nav_list {
        position: relative;
        padding: 10px 0px;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgb(175, 175, 175);
    }
    .dropdown_nav_list .nav_item{
        padding: 10px 0;
    }
}

.nav_link:hover{
    color: var(--primary-color);
    cursor: pointer;
    text-decoration: underline;
}