:root {
  --primary-color: #00a053;
  --active-primary-color: #006735;
  --text-color: #fff;
  --background-color: #151515;
  --nav-color: #0c0c0c;

  --CE-primary-color: #298c9b;
  --CE-active-primary-color: #21737f;
  --CE-secundary-color: #ebbc46;
  --CE-active-secundary-color: #cca43b;

  --text-small: 0.8rm;
  --text-medium: 1rm;
  --text-large: 1.2rm;
}

* {
  padding: 0;
  margin: 0;
  color: var(--text-color);
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  cursor: default;
  justify-content: center;
  scroll-behavior: smooth;
}

html{
  font-size: 100%;
}

main{
  padding-top: 40px;
}

section:not(#banner){
  justify-content: center;
  display: flex;
  padding: 40px;
}

section>*:not(#banner, .ImgSection .content, .triple-section div){
  box-shadow: 0 5px 10px var(--nav-color);
}

Button, a, #nav_list{
  transition: .2s;
}






/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 10px;
  width: 180px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}