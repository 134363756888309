.Button-link{
    background: var(--primary-color);
    border: none;
    box-shadow: inset var(--active-primary-color);
    width: 250px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    text-transform: uppercase;
}

.Button-link:hover{
    box-shadow: inset 250px 0px var(--active-primary-color);
}

.Button-link.disabled {
    background-color: #919191; /* Gray background */
    cursor: not-allowed;
    pointer-events: none; /* Disable hover effects */
}