/* Schedule.css */
.schedule {
    background: var(--background-color);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    gap: 40px;
}

.schedule table {
    border-collapse: collapse;
    background: white;
    text-align: left;
    overflow: hidden;
}

.schedule table caption {
margin-bottom: 10px;
text-transform: uppercase;
text-align: left;
font-weight: 600;
font-size: 20px;

}

.schedule table thead {
    background: var(--primary-color);
}

.schedule table thead th {
padding: 1rem 1.5rem;
text-transform: uppercase;
font-size: 1rem;
font-weight: 400;
}

.schedule table tbody tr {
    background-color: var(--background-color);
    border-top: 1px solid var(--nav-color);
    height: 55px;

}

.schedule table tbody td {
    padding: 1rem 1.5rem;
}