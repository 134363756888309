.CardMediumImg {
    position: relative;
    width: 250px;
}
.CardMediumImg .img-container{
    width: 250px;
    height: 250px;
}

.CardMediumImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}