.triple-section div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    width: 300px;
    height: max-content;
    padding: 45px 15px;
}

.triple-section div:not(:last-child) {
    border-right: solid 1px;
}

.triple-section h5 {
    font-size: 25px;
    font-weight: bolder;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
}

.triple-section div h3 {
    font-weight: bolder;
    font-style: italic;
}




.triple-section {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    padding: 40px;
    background: var(--background-color);
}

.triple-section li,
.triple-section dd {
    width: 230px;
    font-size: 14px;
    list-style: square;
    font-weight: normal;
    text-transform: none;

}

.triple-section li::marker {
    color: var(--CE-secundary-color);
  }

.triple-section dt {
    width: 180px;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
}

.triple-section img {
    width: 250px;
}

.SplitItemText p{
    text-align: center;
    text-transform: none;
    font-size: 18px;
}

@media (max-width: 1000px) {
    .triple-section {
        flex-direction: column;
        align-items: center;
    }

    .triple-section div:not(:last-child) {
        border: none;
        border-bottom: solid 1px;
    }
}