.ImgSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #151515;
    gap: 30px;
    padding: 30px;

}

.ImgSection .content {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ImgSection .content p  {
    text-transform: none;
    line-height: 1.5;
    font-weight: lighter;
}

.ImgSection .content b{
    text-transform: none;
    line-height: 1.5;
}

.image {
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    height: 350px;
}

.image img {
    object-fit: cover;
    width: 100%;
    height: inherit; /* Past de hoogte van de afbeelding aan de container aan */
}

@media (max-width: 786px) {
    .ImgSection {
        flex-direction: column-reverse;
    }

    .image {
        height: 200px;
    }
}
