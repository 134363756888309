.TitleSection{
    width: 100%;
    height: 100px;
    text-align: center;
    background: var(--nav-color);
    display: flex;
    align-items: center;
    font-size: 1.4em;
    text-transform: uppercase;

}
.TitleSection h1{
    font-weight:400;
}