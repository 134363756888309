#banner{
    height: 25vh;
    position: relative;
}

#banner img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

#banner h1{
    text-transform: uppercase;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 25vh;
    font-size: 40px;
}

#banner .gradient{
    position: absolute;
    background: linear-gradient(#00000000, #00000050);
    width: 100%;
    height: 25vh;
}