.CardInfo {
    position: relative;
    width: 250px;
    height: 275px;
    text-transform: none;
}

.CardInfo .content{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10px;
    width: 100%;
    height: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.CardInfo .content h1{
    font-weight: 600;
    text-transform: uppercase;

}

.CardInfo .content ul{
text-align: left;
padding: 30px;
}

.CardInfo .content ul li a{
    text-decoration: underline;
    color: var(--active-primary-color);
    cursor: pointer;
}

.CardInfo Button {
    position: absolute;
    bottom: 0;
}