.CardLargeImg {
    position: relative;
    width: 250px;
    height: 350px;
    overflow: hidden;

}

.CardLargeImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s;
    cursor: pointer;

}

.CardLargeImg:hover img {
    transform: scale(1.05);
}

.CardLargeImg div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#00000000, #00000080);
    pointer-events: none;
}

.CardLargeImg h1{
    position: absolute;
    bottom: 0;
    height: 55px;
    width: 250px;
    text-align: center;
    font-weight: 400;
    font-size: 28px;
    text-transform: uppercase;
    cursor: pointer;
}
